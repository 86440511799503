<template>
  <div
    class="column px-4 mb-4 is-multiline"
    v-if="hasOtherFields"
  >

    <hr class="mt-0">

    <div class="title is-4 pl-2">
      Campos Adicionales
    </div>

    <div class="columns is-multiline px-2">

      <div
        v-for="(field, index) in otherFields"
        :key="`field-${index}`"
        :class="wichType(field)" >

        <!-- Strings -->
        <div v-if="field.fieldType === 'string'">

          <b-field :label="field.name">

            <b-tooltip
              v-if="field.tooltip"
              :label="field.tooltip"
              :position="field.tooltipDirection">

              <b-input
                :disabled="!field.isActive"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                type="textarea"
                v-if="field.options === 'textarea'"
                v-model="field.value"/>

              <b-input
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                v-else
                v-model="field.value"/>

            </b-tooltip>

            <div v-else>

              <b-input
                :disabled="!field.isActive"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                type="textarea"
                v-if="field.options === 'textarea'"
                v-model="field.value"/>

              <b-input
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                v-else
                v-model="field.value"/>

            </div>

          </b-field>

        </div>

        <!-- Number -->
        <div v-if="field.fieldType === 'number'"  >

          <b-field :label="field.name">

            <b-tooltip
              v-if="field.tooltip"
              :label="field.tooltip"
              :position="field.tooltipDirection">

              <b-input
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                type="number"
                v-model="field.value"/>

            </b-tooltip>

            <div v-else>

              <b-input
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                type="number"
                v-model="field.value"/>

            </div>

          </b-field>

        </div>

        <!-- Dates -->
        <div v-if="field.fieldType === 'Date'"  >

          <b-field :label="field.name">

            <b-tooltip
              v-if="field.tooltip"
              :label="field.tooltip"
              :position="field.tooltipDirection">

              <b-datepicker
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                :type="field.options === 'month' ? 'month' : null"
                :unselectable-days-of-week="[0,6]"
                range
                v-if="field.options === 'range'"
                v-model="field.rangeDates"/>

              <b-datepicker
                :disabled="!field.isActive"
                :icon="field.icon" :unselectable-days-of-week="[0,6]"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                :type="field.options === 'month' ? 'month' : null"
                v-else
                v-model="field.value"/>

            </b-tooltip>

            <div v-else>

              <b-datepicker
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                :type="field.options === 'month' ? 'month' : null"
                :unselectable-days-of-week="[0,6]"
                range
                v-if="field.options === 'range'"
                v-model="field.rangeDates"/>

              <b-datepicker
                :disabled="!field.isActive"
                :icon="field.icon"
                :placeholder="field.placeholder"
                :required="field.isRequired"
                :type="field.options === 'month' ? 'month' : null"
                :unselectable-days-of-week="[0,6]"
                v-else
                v-model="field.value"/>

            </div>

          </b-field>

        </div>

        <!-- Boolean -->
        <div v-if="field.fieldType === 'boolean'"  >

          <b-field :label="field.name">

            <b-tooltip
              v-if="field.tooltip"
              :label="field.tooltip"
              :position="field.tooltipDirection">

              <b-switch
                v-model="field.value"
                :disabled="!field.isActive"
                :placeholder="field.placeholder"
                :required="field.isRequired"></b-switch>

              <b-icon :icon="field.icon" :type="field.value ? 'is-primary' : ''"></b-icon>

            </b-tooltip>

            <div v-else>

              <b-switch
                v-model="field.value"
                :disabled="!field.isActive"
                :placeholder="field.placeholder"
                :required="field.isRequired">

              </b-switch>

              <b-icon
                :icon="field.icon"
                :type="field.value ? 'is-primary' : ''"></b-icon>
            </div>

          </b-field>

        </div>

        <!-- tags Array -->
        <div
          v-if="field.fieldType === 'Array'"
          style="width: fit-content;">

          <b-field :label="field.name">

            <b-icon :icon="field.icon"></b-icon>

            <b-tooltip
              v-if="field.tooltip"
              :label="field.tooltip"
              :position="field.tooltipDirection">

              <div style="display: grid; grid-template-columns: repeat(2, 1fr)">

                <b-checkbox
                  class="mb-2"
                  v-model="field.value"
                  v-for="(option,index) in field.options"
                  :key="`option-${index}`"
                  :native-value="option"
                  :disabled="!field.isActive"
                  :required="field.isRequired">
                    {{ option }}
                </b-checkbox>

              </div>

            </b-tooltip>

            <div v-else>

              <div style="display: grid; grid-template-columns: repeat(2, 1fr)">

                <b-checkbox
                  class="mb-2"
                  v-model="field.value"
                  v-for="(option,index) in field.options"
                  :key="`option-${index}`"
                  :native-value="option"
                  :disabled="!field.isActive"
                  :required="field.isRequired">
                    {{ option }}
                </b-checkbox>

              </div>

            </div>

          </b-field>

        </div>

        <!-- select Array -->
        <div v-if="field.fieldType === 'Select'">

          <b-field :label="field.name">

            <b-tooltip
              v-if="field.tooltip"
              :label="field.tooltip"
              :position="field.tooltipDirection">

              <b-select
                v-model="field.value"
                :disabled="!field.isActive"
                :placeholder="field.placeholder"
                :icon="field.icon"
                expanded
                :required="field.isRequired">

                <option
                  v-for="(option, index) in field.options"
                  :value="option"
                  :key="`option-${index}`">
                  {{ option }}
                </option>

              </b-select>

            </b-tooltip>

            <div v-else>

              <b-select
                v-model="field.value"
                :disabled="!field.isActive"
                :placeholder="field.placeholder"
                :icon="field.icon"
                expanded
                :required="field.isRequired">

                <option
                  v-for="(option, index) in field.options"
                  :value="option"
                  :key="`option-${index}`">
                  {{ option }}
                </option>

              </b-select>

            </div>

          </b-field>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  props:{
    otherFields: {
      type: Array,
      required: true
    },
    hasOtherFields: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },

  mounted() {
    
  },

  methods: {
    wichType(field){
      if(field.options === 'default' ) return 'column is-4'
      if(field.options === 'textarea' ) return 'column is-6'
      if(field.fieldType === 'number' ) return 'column is-4'
      if(field.fieldType === 'Date' ) return 'column is-4'
      if(field.fieldType === 'boolean' ) return 'column is-2'
      if(field.fieldType === 'Array' ) {
        return field.options.length > 4 ? 'column is-6' : 'column is-4'
      }
      if(field.fieldType === 'Select' ) return 'column is-4'
    },
  },
};
</script>

<style lang="scss" scoped>

</style>